.TopSection {
    position: fixed;
    display: flex;
    width: 100%;
    padding: 20px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #989898;
}

.TopSection p {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
}

.NextStepDiv {
    position: absolute;
    right: 150px;
    top: 10px;
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}


.MainSection {
    padding: 20px;
    height: 50vh;
    width: 95%;
    margin: auto;
    margin-top: 80px;
    display: flex;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.ShowFileImage {
    width: 70%;
    padding: 10px;
}

.InputFileDiv {
    padding: 10px;
    width: 30%;

}

.SubTitle {
    width: 100%;
    text-align: center;
    font-size: 22px;

}

.DropZone {
    width: 95%;
    height: 35vh;
    margin: auto;
    margin-top: 10px;
    border: 2px dashed #aaa;
    margin-bottom: 10px;
    border-radius: 10px;
    display: "flex";
    align-content: center;
    justify-items: center;

}

.DropZone p {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
}

.uploadButton {
    width: 100%;
    height: 50px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.DropZone:hover {
    background-color: #f0f0f0;
}

.DisplaySection {
    width: 95%;
    margin: auto;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.TableDiv {
    width: 100%;
    max-height: 60vh;
    overflow-x: auto;
}

.TableDiv table {
    width: 100%;
    border-collapse: collapse;
}

.TableDiv th,
.TableDiv td {
    border: 1px solid #ddd;
    padding: 8px;
}

.TableDiv th {
    background-color: #3471c0;
    color: white;
    text-align: left;
}

.TableDiv tr:nth-child(even) {
    background-color: #f9f9f9;
}

.TableDiv tr:hover {
    background-color: #ddd;
}

.TableDiv th {
    position: sticky;
    top: 0;
    z-index: 1;
}

.TableDiv td {
    position: relative;
}

.TableDiv td::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    pointer-events: none;
}

.OutputSection {
    width: 95%;
    margin: auto;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 80px;
    margin-bottom: 20px;

}

.CompareTitleDiv {
    width: 100%;
    text-align: center;
    font-size: 22px;
    margin-bottom: 20px;
}

.OutputDiv {
    position: relative;
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
}

.OutputResult {
    position: absolute;
    top: 25px;
    right: 30px;
}